$base-color: #947662;
$secondary-color: #000000;
$secondary-font-color: #000000;

.accordianComponent {
    margin-bottom: 0.5rem;
    h3 {
        font-weight: 100;
    }
}

.horizontalTexts {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.accordianFlexBox {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

.listHeading {
    color:$secondary-font-color;
}

.listHeading .firstHeading {
    margin-top: 100px;
}

.listBox{
    list-style-position: outside;
    padding: 0px;
    margin-top: -1rem;
    margin-bottom: 0rem;
}

.listItem {
    color:$secondary-font-color;
    margin:0.25rem;
    list-style-type: none;
    list-style-position: outside;
    margin: 0.75rem;
    margin-left: 3rem;
}
