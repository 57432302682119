// ContactUs.scss

$button-color: #9B0C3E;
$secondary-color-Academy: #F3E9E0;
$secondary-font-color-Academy: #000000;
$base-color: #947662;
$secondary-color: #F3E9E0;
$secondary-font-color: #000000;


.contact-us-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    font-family: "BodyFont";

    h2 {
      text-align: center;
      margin-bottom: 20px;
    }

    h3 {
        font-weight: 100;
        text-align: center;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      label {
        margin-bottom: 5px;
        font-family: "BodyFont";
      }
  
      input,
      textarea {
        margin-bottom: 10px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-family: "BodyFont";
      }
  
      button {
        padding: 1rem;
        font-size: large;
        border: none;
        border-radius: 2rem;
        font-family: "BodyFont";
        width: fit-content;
        align-self: center;
      }
    }
  }

  .contact-us-text {
    margin-bottom: 2rem;
  }
  