$base-color: #947662;
$secondary-color: #F3E9E0;
$secondary-font-color: #000000;


.mainSection {
    background-color: $base-color;
    color:$secondary-color;
        h1 {
            font-weight: 300;
        }
        h2 {
            font-weight: 100;
        }
        h3 {
            font-weight: 300;
        }
}

.pageTitle {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
}

.asterisk {
    margin:0.5rem;
    font-weight: 100;
}

.image1 {
    margin: 0px;
    img {
        display: block;
    }
}

.prePostCare {
    background-color: $secondary-color;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0rem;
    padding-top: 10px;
    color: $secondary-color;
    padding-bottom: 2rem;
}

.accordianContainer {
    background-color: $base-color;
    margin-bottom: 1rem;
}

.accordianFlexBox {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    color:#F3E9E0;
}

.darkFontInjectables {
    color:$secondary-color;
}

.listHeadingInjectables {
    color:$secondary-color;
    margin-bottom: 1rem;
}

.firstHeading {
    margin-top: 0px;
    margin-bottom: 1rem;
}

.listItemInjectables {
    color:$secondary-color;
    margin:0.25rem;
    font-weight: 100;
    color: $secondary-color;
    margin-left: 3rem;
}

.pageFooterInjectables {
    background-color: $secondary-color;
    display: block;
}

li {
    list-style-type: "-";
    list-style-position: outside;
}

ul {
    list-style-position: outside;
    padding: 0px;
}

//full view
@media (min-width: 1024px) {

    .pricingAndImage {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem;
    }

    .pricingBox {
        flex-grow: 2;
        margin-right: 2rem;
    }

    .image1 {
        max-width: 50%;
        align-self: center;
    }

    img {
        border-radius: 2%;
    }
}
