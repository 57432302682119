@import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext');
/* change family name to desired font and call it in the class name, + sign instead of space */

$appbar-background: #9B0C3E;

.outerAppBar {
    z-index: 0;
}

.appBar{
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 0;
}

.appBarLogo{
    max-width: 40px;
}

.appBarInsta{
    color: rgba(26, 23, 23, 0.747);
    opacity: 1;
    margin-left: 1rem;

}

.appBarName{
    margin-top: 0.1rem;
    font-size: 1.25rem;
    font-weight: 400;
    flex-grow: 1;
    text-align: center;
}

.headerSpacer {
    flex-grow: 0;
    width: 0px;
}

.headerLogo {
    height: 40px;
}

.appBarLink{
    text-decoration: none;
}

.appBarBook{
    color: rgb(10, 10, 10);
    font-weight: 400;
    align-self: center;
    font-size: 1rem;
}

@media (max-width: 1439px) {
    .headerSpacer {
        flex-grow: 0;
    }
}



@media (min-width: 1440px) {

    .appBar {
        height: 60px;
    }

    .appBarName {
        width: fit-content;
        flex-grow: 0;

        .headerLogo {
            width: fit-content;
        }
    }

    .headerSpacer {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: flex-end;
    }

    .appBarInsta {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .meetTheTeam {
        font-family: 'TitleFont';
        font-size: 25px;
    }
}

