$base-color-Team: #F3E9E0;
$base-color-font-Team: #000000;
$secondary-color-Team: #F3E9E0;
$secondary-font-color-Team: #000000;


.mainSectionTeam {
    background-color: $base-color-Team;
    color: $base-color-Team;
}

.pageHeaderTeam {
    color:$base-color-font-Team;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.TeamComponent {
    display:flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out; // Set your preferred transition duration and easing function
    color:$base-color-font-Team;
}

.TeamComponent.visible {
    opacity: 1;
}

.teamMemberInfoBox{
    margin-bottom: 1rem;
    align-self: center;
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.teamMemberImage {
    padding: 0px;
    margin: 0px;
    img {
        border-radius: 100px;
    }
}

.nameTeamMember {
    margin-bottom: 0.5rem;
}

.positionTeamMember {
    max-width: fit-content;
    align-self: center;
}

.qulificationsTeamMember {
    text-align: center;
}

.bookNowButton {
    font-family: "TitleFont";
    font-size: large;
    max-width: fit-content;
    align-self: center;
    text-transform: none;
    padding: 1rem;
    border-radius: 2rem;
}

.fillerImageTeam {
    padding: 0px;
    margin: 0px;
}

.pageFooterTeam {
    background-color: $base-color-Team;
}

@media (min-width: 768px) {
    .teamMemberImage
    {
        width: 70%;
        margin: auto;
    }

    .fillerImageTeam {
        width: 90%;
        margin: auto;
    }
}
