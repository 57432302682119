.map{
    width:500px;
    height:500px;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

@media only screen and (max-width:320px) {
    .map{
        width:275px;
    }
}

@media only screen and (min-width:321px) and (max-width:425px) {
    .map{
        width:320px;
    }
}

@media only screen and (min-width:426px) and (max-width:768px){
    .map{
        min-width:400px;
    }
}

@media only screen and (min-width:769px) {
    .map{
        min-width:700px;
    }
}
