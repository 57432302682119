$base-color-Academy: #e79796;
$secondary-color-Academy: #F3E9E0;
$secondary-font-color-Academy: #000000;


.mainSectionAcademy {
    background-color: $base-color-Academy;
    color: #000000;
}

.AcademyComponent {
    display:flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out; // Set your preferred transition duration and easing function
    h2 {
        font-family: "BodyFont";
        font-weight: 100;
    }
}

.AcademyComponent.visible {
    opacity: 1;
}

.leftBox {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    
    .checkMark {
        flex: 1;
        align-self: center;

    }

    h2 {
        flex: 3;
    }

}

.rightBox {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .checkMark {
        flex-grow: 1;
        align-self: center;
    }

    h2 {
        flex: 3;
        text-align: right
    };

}

.centerBox {
    align-self: center;
    width:70%;
    text-align: center;
}

.fade-in-element {
    opacity: 0;
    transition: opacity 0.2s ease-in-out; // Set your preferred transition duration and easing function
}

.fade-in {
    opacity: 1 !important; // Use !important to override inline styles if necessary
}

.fade-in-element.visible {
    opacity: 1;
  }

.paragraph {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out; // Set your preferred transition duration and easing function
    h2 {
        font-family: "BodyFont";
        font-weight: 100;
        text-align: center;
    }
}

.paragraph.visible {
    opacity: 1;
}

.AcademyDefintionBlock {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
}

.pageTitleAcademy{
    width: 100%;
    text-align: center;
}

.fillerImageAcademy {
    padding: 0px;
    margin: 0px;
    //background-color: #F3E9E0;
    img {
        display: block;
    }
}

.prePostCareAcademy {
    background-color: $secondary-color-Academy;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0rem;
    padding-top: 20px;
    color: $secondary-font-color-Academy;
    padding-bottom: 2rem;
}

.accordianContainerAcademy {
    background-color: $secondary-color-Academy;
    margin-bottom: 1rem;
}

.accordianFlexBoxPAcademy {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

.darkFontAcademy {
    color:$secondary-font-color-Academy;
}

.listHeadingAcademy {
    color:$secondary-font-color-Academy;
}

.listHeading .firstHeading {
    margin-top: 100px;
}

.listItemAcademy {
    color:$secondary-font-color-Academy;
    margin:0.25rem;
}

.pageFooterAcademy {
    background-color: $secondary-color-Academy;
}

//full view
@media (min-width: 1024px) {

    .horizontalFlexAcademy {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 3rem;
    }

    .AcademyComponent {
        flex-grow: 2;
        margin-right: 2rem;
        font-size: 14px;
    }

    .fillerImageAcademy {
        max-width: 50%;
        align-self: center;
        margin: auto;
    }

    .contactBox {
        padding-top: 2rem;
        background-color: $secondary-color-Academy;
    }
}