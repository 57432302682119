.twoTextContainer {
    width: 100%;
    margin: auto;
}

@media (min-width: 426px) and (max-width: 768px){
    .twoTextContainer {
        width: 70%;
    }
}

@media (min-width: 769px) and (max-width: 1022px){
    .twoTextContainer {
        width: 50%;
    }
}

@media (min-width: 1024px) {
    .twoTextContainer {
        width: 100%;
    }
}