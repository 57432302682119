$contrast-color:#e79796;
$light-color:#F3E9E0;
$pink-color: #E5BDBE;
$dark-color: #000000;
$yellow-color: #e9dbcd;
$green-color: #C6C09C;
$dark-pink-color: #b98a8a;


html, body{
    margin: 0;
    height:100%
}

.home-page-main {
    background-color: $contrast-color;
    display: flex;
    flex-direction: column;

    h1 {
        color:$light-color;
    }

    h4 {
        color:$light-color;
    }

}

.circularText {
    position: absolute;
    width: 100%;
    align-self: center;
}

.landingImageHome {
    width: 100%;

    img {
        margin-top: 1rem;
        border-top-left-radius:150px;
        border-top-right-radius: 150px;
        display: block;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
}

.introduction {
    margin-top: 3rem;
    width: 100%;
    align-self: center;
    text-align: center;
    margin-bottom: 3rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
}

.introduction.visible {
    opacity: 1;
}

.fillerImageHome {
    background-color: $light-color;
    height: fit-content;
    img {
        display: block;
        border-radius: 0;
      }
}

.secondBlock {
    background-color: $light-color;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    h1 {
        color: $dark-color;
        width: fit-content;
        margin-bottom: 2rem;
        align-self: center;
    }
    h4 {
        text-align: center;
        align-self: center;
        margin-bottom: 2rem;
        width: 90%;
    }
}

.horizontalFlexHome {
    background-color: $light-color;
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    justify-content: center;
    
    .horizontalTextHome {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

        h1 {
            color: $dark-color;
            width: fit-content;
            margin-bottom: 1rem;
            align-self: center;
        }
        h4 {
            color: $dark-color;
            text-align: center;
            align-self: center;
            margin-bottom: 2rem;
            width: 90%;
        }
    }




}

.secondBlock.visible {
    opacity: 1;
}

.meetTheTeam {
    font-family: "BodyFont";
    max-width: fit-content;
    align-self: center;
    text-transform: none;
    border-radius: 5rem;
    font-size: large;
}

.thirdBlock {
    padding-top: 2rem;
    background-color: $contrast-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background-color: #f4cfc7;

    h2 {
        color: $dark-color;
        margin-bottom: 2rem;
    }
}

.thirdBlock.visible {
    opacity: 1;
}


.serviceCard {
    align-self: center;
    border: #F3E9E0 solid;
    text-align: center;
    background-color: $light-color;
    margin-bottom: 2rem;
    border-radius: 2rem;
    width: 100%;

    h2 {
        margin-bottom: 0px;
        color: $dark-color;
        
    }
}

.fourthBlock {
    width: 100%;
    background-color: $light-color;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.wideScreen {
    margin: 1rem;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.meetTheTeam {
    align-self: center;
}

.fourthBlock.visible {
    opacity: 1;
}

.locationHome {
    display:flex;
    flex-direction: column;
    align-items: center;

    .addressLocation {
        margin-bottom: 2rem;
    }
}

.fifthBlock {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $dark-pink-color;
    h2 {
        color: $light-color;
        margin-bottom: 2rem;
    }
}

.pageFooterHome {
    background-color: $dark-pink-color;
}

//tablet view
@media (min-width: 481px) and (max-width: 769px) {
    .introduction {
        flex-direction: row;
        justify-content: space-evenly;

        .description {
            margin-top: 1rem;
            h4 {
                color: $light-color;
            }
        }
    }

    .leftSideBox {
        flex-direction: column;
        width: 50%;
        justify-content: space-evenly;
    }

    .landingImageHome {
        width: 50%;
    }

    .circularText {
        position: absolute;
        width: fit-content;
        //align-self: flex-start;
        //margin-left: -19rem;
    }

    .servicesBlock {
        display: flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content: space-evenly;
    }

    .serviceCard {
        max-width: 40%;
        margin: 2rem;
        align-self: center;
    }

    .fillerImageHome {
        max-height: 500px;
        overflow: hidden;
    }
}

//full view
@media (min-width: 770px) and (max-width: 1439px){

    .home-page-main {
        h4 {
            font-size: 20px;
        }
    }

    .introduction {
        flex-direction: row;
        justify-content: space-evenly;

        .description {
            margin-top: 1rem;
            h4 {
                color: $light-color;
            }
        }
    }

    .leftSideBox {
        flex-direction: column;
        width: 50%;
        justify-content: space-evenly;
    }

    .landingImageHome {
        width: 50%;
    }

    .secondBlock {
        h4 {
            font-size: 20px;
        }
    }

    .circularText {
        position: absolute;
        width: fit-content;
        align-self: flex-start;
        //margin-left: -340px;
    }

    .servicesBlock {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .serviceCard {
        max-width: 50%;
        margin: 2rem;
        align-self: center;
    }

    .fillerImageHome {
        max-height: 500px;
        overflow: hidden;
    }
}

@media (min-width: 1440px) {
    
    .home-page-main {
        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 32px;
        }
        h4 {
            font-size: 20px;
        }
    }

    .introduction {
        flex-direction: row;
        justify-content: space-evenly;

        .description {
            margin-top: 1rem;
            h4 {
                color: $light-color;
            }
        }
    }

    .leftSideBox {
        flex-direction: column;
        width: 50%;
        justify-content: space-evenly;
    }

    .landingImageHome {
        width: fit-content;
    }

    .secondBlock {
        h4 {
            font-size: 20px;
        }
    }

    .circularText {
        position: absolute;
        width: fit-content;
    }

    .servicesBlock {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .serviceCard {
        max-width: 50%;
        margin: 2rem;
        align-self: center;
    }

    .fillerImageHome {
        max-height: 500px;
        overflow: hidden;
    }

    .fourthBlock {
        flex-direction: row;
        justify-content: space-around;
    }

    .contact-us-container
    {
        align-self: center;
        margin: 1rem;
    }    
    .wideScreen {
        margin: 1rem;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-left: 5rem;
    }

    .meetTheTeam {
        align-self: center;
    }
}


@media (min-width: 1024px) {

    .horizontalFlexHome {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 3rem;

        .fillerImageHome {
            width: 200%;
            border-radius: 5%;
        }

        .horizontalTextHome {
            text-align: center;
            padding: 1rem;
            align-self: center;
        }
    }
}


