@import url('https://fonts.googleapis.com/css?family=Calligraffitti:regular,bold,italic&subset=latin,latin-ext');

@font-face {
  font-family: "Delmon-Delicate";
  src: url('assets/fonts/Delmon\ Delicate.woff2');
  src: url('assets/fonts/Delmon\ Delicate.woff2') format('woff2'),
}

@font-face {
  font-family: "Optiamway";
  src: url('assets/fonts/OPTIAmway.woff2');
  src: url('assets/fonts/OPTIAmway.woff2') format('woff2'),
}

@font-face {
  font-family: "DancingScript-Bold";
  src: url('assets/fonts/DancingScript-Bold.woff2');
  src: url('assets/fonts/DancingScript-Bold.woff2') format('woff2'),
}

@font-face {
  font-family: "DancingScript-Regular";
  src: url('assets/fonts/DancingScript-Regular.woff2');
  src: url('assets/fonts/DancingScript-Regular.woff2') format('woff2'),
}

@font-face {
  font-family: "TitleFont";
  src: url('assets/fonts/Roca\ Light.woff2');
  src: url('assets/fonts/Roca\ Light.woff2') format('woff2'),
}

@font-face {
  font-family: "BodyFont";
  src: url('assets/fonts/NotoSerifExtraLight.woff2');
  src: url('assets/fonts/NotoSerifExtraLight.woff2') format('woff2'),
}



text {
  font-family: "TitleFont";
  font-weight: 100;
}

h1 {
  font-family: "TitleFont";
  margin: 0px;
  font-weight: 100;

} 

h2 {
  font-family: "TitleFont";
  margin: 1rem;
  display: flex;
  justify-content: center;
  font-weight: 100;
}

h3 {
  font-family: "BodyFont";
  font-weight: 100;
  margin: 0px;
  font-weight: 200;
  /* color: #F3E9E0; */
}

h4 {
  font-family: "BodyFont";
  font-weight: 100;
  margin: 0px;
}

img {
  width: 100%;
}

.pageTitleBox {
  display: flex;
  justify-content: center;
  border-color: black;
  width: 100%;
  margin-bottom: 1rem;
  margin-top:1rem;
}

.pageSubTitleBox {
  display: flex;
  justify-content: center;
  border-color: black;
  width: 100%;
  margin-bottom: 1rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Calligraffitti';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

