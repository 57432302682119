$lightColor: #F3E9E0;
$darkBackgroundColor: #9B0C3E;


.shadowContainer {
    display: flex;
    flex-direction: column;
    h2 {
        color: $lightColor;
        margin: 0;
        font-size: 36px;
    }
}

.firstShadow {
    margin-top: 2rem;
    background-color: $darkBackgroundColor;
    z-index: 5;
    max-height: fit-content;
}

.secondShadow {
    margin-top: -0.9rem;
    background-color: $darkBackgroundColor;
    max-height: fit-content;
    z-index: 4;
}

.thirdShadow {
    margin-top: -1.5rem;
    z-index: 3;
    background-color: $darkBackgroundColor;
    max-height: fit-content;
}

.fourthShadow {
    margin-top: -1.9rem;
    background-color: $darkBackgroundColor;
    max-height: fit-content;
    z-index: 2;
}