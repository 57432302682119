$base-color-prp: #e79796;
$base-font-color-prp: #000000;
$secondary-color-prp: #F3E9E0;
$secondary-font-color-prp: #000000;


.mainSectionPRP {
    background-color: $base-color-prp;
    color: $base-font-color-prp;
    h2, h3, h4 {
        font-weight: 100;
    }
}

.prpDefintionBlock {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
}

.pageTitlePRP {
    width: 100%;
    text-align: center;
}

.fillerImagePRP {
    padding: 0px;
    margin: 0px;

    img {
        display: block;
    }
}

.prePostCarePRP {
    background-color: $secondary-color-prp;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0rem;
    padding-top: 20px;
    color: $secondary-font-color-prp;
    padding-bottom: 2rem;
}

.accordianContainerPRP {
    background-color: $secondary-color-prp;
    margin-bottom: 1rem;
}

.accordianFlexBoxPRP {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

.darkFontPRP {
    color:$secondary-font-color-prp;
}

.listHeadingPRP {
    color:$secondary-font-color-prp;
}

.listHeading .firstHeading {
    margin-top: 100px;
}

.listItemPRP {
    color:$secondary-font-color-prp;
    margin:0.25rem;
}

.pageFooterPRP {
    background-color: $secondary-color-prp;
}

//full view
@media (min-width: 1024px) {

    .pricingAndImagePRP {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 3rem;
    }

    .pricingBox {
        flex-grow: 2;
        margin-right: 2rem;
    }

    .fillerImageSkin {
        max-width: 50%;
        align-self: center;
    }

    .fillerImagePRP {
        max-width: 70%;
        align-self: center;
        margin: auto;
    }

    .secondaryBlockPRP {
        padding-top: 3rem;
        background-color: $secondary-color-prp;
    }
}

