$base-color-skin: #E5BDBE;
$secondary-color-skin: #F3E9E0;
$secondary-font-color-skin: #000000;


.mainSectionSkin {
    background-color: $base-color-skin;
    color: #eacfcf;
}

.pageTitleSkin {
    width: 100%;
    text-align: center;
}

.fillerImageSkin1 {
    padding: 0px;
    margin: 0px;
    img {
        display: block;
    }
}

.fillerImageSkin2 {
    padding: 0px;
    margin: 0px;
    background-color: #F3E9E0;
    img {
        display: block;
    }
}

.prePostCareSkin {
    background-color: $secondary-color-skin;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0rem;
    padding-top: 20px;
    color: $secondary-font-color-skin;
    padding-bottom: 2rem;
}

.accordianContainerSkin {
    background-color: $secondary-color-skin;
    margin-bottom: 1rem;
}

.accordianFlexBoxSkin {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

.darkFontSkin {
    color:$secondary-font-color-skin;
}

.listHeadingSkin {
    color:$secondary-font-color-skin;
}

.listHeading .firstHeading {
    margin-top: 100px;
}

.listItemSkin {
    color:$secondary-font-color-skin;
    margin:0.25rem;
}

.pageFooterSkin {
    height: 2rem;
    background-color: $secondary-color-skin;
}

@media (min-width: 1024px) {

    .pricingAndImage {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem;
    }

    .pricingBox {
        flex-grow: 2;
        margin-right: 2rem;
    }

    .fillerImageSkin1 {
        max-width: 50%;
        align-self: center;
    }

    .fillerImageSkin2 {
        max-width: 50%;
        justify-self: center;
        margin: auto;
        padding-top: 2rem;
    }

    .secondaryBlock {
        background-color: $secondary-color-skin;
    }

}
