$appbar-background: #b98a8a;
$appbar-font-color: #F3E9E0;

.menuContainer {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $appbar-background;
  z-index: 1200;
}

.menuCloser {
  padding: 0px;
  margin-left: 0.95rem;
  margin-top: 0.55rem;
}

.menuHeader {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.menuTitle {
  height: 40px;
  margin-top: 0.55rem;
  width: 100%;
  color: white;
  text-align: center;
  font-family: "TitleFont";
  font-size: 1.25rem;
  margin-right: 2rem;
  padding-right: 1.45rem;
  margin-bottom: 0rem;
}

.fadeIn {
  opacity: 1;
  z-index: 1200;
}

.fadeOut {
  opacity: 0;
  z-index: 0;
}

.menu {
  text-align: center;
  color:$appbar-font-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  h1 {
    font-weight: 100;
  }
}

.menu h1 {
  margin: 10px 0;
  // cursor: pointer;
}

.bookNowLink {
  text-decoration:none;
  margin-top: 10px;
  // cursor: pointer;
  //background-color: ;
  align-self: center;
  border-radius: 10px;
  padding: 0 2rem;

  h1 {
    color: $appbar-font-color;
  }
}

.drawerListItem {
  justify-content: center;
  color:$appbar-font-color;
  h1 {
    font-weight: 100;
  }
}