.image-Box {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
}

.image-Gallery {
    width: 90%;
    border-radius: 0.5rem;
    position: relative;
}

.instaBox {
    margin: 0.5rem;
    border-radius: 2rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
    
    img {
        align-self: center;
    }

    h3 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        align-self: center;
        text-align: center;
    }



}