// ContactPage.scss
$mainBackgroundColor:#F3E9E0;
$mainFontColor:#000000;

.contact-page {
  display: flex;
  flex-direction: column;
  background-color: $mainBackgroundColor;

  .contact-info {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      margin-bottom: 20px;
    }

    .location, 
    .location-hours {
      display: flex;
      flex-direction: column;

      h4 {
        font-weight: 100;
        align-self: center;
        text-align: center;
        max-width: 90%;
      }

      ul {
        list-style: none;
        align-self: center;
        width: fit-content;

        li {
          margin-bottom: 5px;
          font-family: "BodyFont";
          list-style-type: none;

          h4 {
            max-width: 100%;
          }
        }
      }

      .addressLocation {
        display: flex;
        flex-direction: column;
        h4 {
          margin:0;
          align-self: center;
        }
      }
    }
  }

  .mapBox {
    align-self: center;
    width: fit-content;
  }

  .contact-details {
    display: flex;
    flex-direction: column;

    h4 {
      align-self: center;
      font-weight: 100;
    }
  }
}


@media (min-width: 768px) {

  .contactLandingImage
  {
    width: 80%;
    margin: 2rem auto 0rem;

    img {
      border-radius: 5%;
    }
  }
}

@media (min-width: 1024px) {

  .contactLandingImage
  {
    max-width: 1000px;
  }
}
